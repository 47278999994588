import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { composeCPProductId } from '~/common/contactpigeon';
import ProductStatuses from '~/components/product/productPage/ProductStatuses';
import ProductCardColors from './ProductCardColors';
import ProductCardImage from './ProductCardImage';
import ProductCardPrice from './ProductCardPrice';
import ProductCardSizes from './ProductCardSizes';
import useProductCard from './lib/useProductCard';

const ProductCard = ({ product, showDimensions = true, onClick = () => {} }) => {
	const { t } = useTranslation();

	const handledProduct = { ...product, ...useProductCard(product) };

	return (
		<div className="product-card" data-product-id={composeCPProductId(product, false)} onClick={onClick}>
			<ProductStatuses
				product={handledProduct}
				tags={handledProduct.tags}
				statuses={handledProduct.statuses}
				discount={handledProduct.discount}
			/>

			<ProductCardImage product={handledProduct} />

			<div className="product-info">
				<Link to={product.eurl}>
					<h3>
						{product.nm} <br /> {handledProduct.code}
					</h3>
					{/* <h4 dangerouslySetInnerHTML={{ __html: product.dsm }}></h4> */}

					<ProductCardPrice product={handledProduct} />

					{showDimensions && (
						<div className="dimension-info">
							<div className="dimension-info-container">
								<ProductCardColors product={handledProduct} />
								<ProductCardSizes product={handledProduct} />
							</div>
						</div>
					)}
				</Link>
			</div>
		</div>
	);
};

ProductCard.propTypes = {
	product: PropTypes.object,
	showDimensions: PropTypes.bool
};

export default ProductCard;
