import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { convertImageUrl_L_to_M } from '~/backend/helper';
import closeicon from '~/images/close-button.svg';
import BrandsLayout from './BrandsLayout';
import CollectionLayout from './CollectionLayout';
import DefaultLayout from './DefaultLayout';
import SportsLayout from './SportsLayout';

const defineLayout = code => {
	if (code === 'BRANDS') return 'brands-layout';
	if (code === 'OFFERS') return 'brands-layout';
	if (code === 'SPORTS') return 'sports-layout';
	if (code === 'COLLECTION') return 'collection-layout';
	return 'default-layout';
};

const SubMenu = ({ parentMenuItem, menuItems, setActiveMenuItem, activeSubMenuItem, setActiveSubMenuItem }) => {
	const { t } = useTranslation();

	return (
		<div className="sub-menu">
			<hr />
			<button className="close-menu-desktop" onClick={() => setActiveMenuItem({})}>
				<img src={closeicon} alt="close menu" />
			</button>
			<button className="go-back-mobile" onClick={() => (activeSubMenuItem.id ? setActiveSubMenuItem({}) : setActiveMenuItem({}))}>
				ΠΙΣΩ
			</button>
			<div className={`sub-menu-container content-wrapper ${defineLayout(parentMenuItem.cd)}`}>
				<div className="columns">
					{defineLayout(parentMenuItem.cd) === 'brands-layout' ? (
						<BrandsLayout parentMenuItem={parentMenuItem} menuItems={menuItems} />
					) : defineLayout(parentMenuItem.cd) === 'sports-layout' ? (
						<SportsLayout parentMenuItem={parentMenuItem} menuItems={menuItems} />
					) : defineLayout(parentMenuItem.cd) === 'collection-layout' ? (
						<CollectionLayout
							parentMenuItem={parentMenuItem}
							menuItems={menuItems}
							setActiveMenuItem={setActiveMenuItem}
							activeSubMenuItem={activeSubMenuItem}
							setActiveSubMenuItem={setActiveSubMenuItem}
						/>
					) : (
						<DefaultLayout
							parentMenuItem={parentMenuItem}
							menuItems={menuItems}
							activeSubMenuItem={activeSubMenuItem}
							setActiveSubMenuItem={setActiveSubMenuItem}
						/>
					)}
				</div>

				{defineLayout(parentMenuItem.cd) === 'default-layout' && parentMenuItem.img1 && (
					<div className="menu-item-image">
						<img src={convertImageUrl_L_to_M(parentMenuItem.img1)} alt={parentMenuItem.tl} />
						<span className="category-img-title">
							<Link to={parentMenuItem?.nu}>{t('see_all_products')}</Link>
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default SubMenu;
